<template>
  <li
    :class="{ active }"
  >
    <b-link
      v-wave="{color: '#777'}"
      @click.prevent="isOpen = !isOpen"
      :aria-expanded="isOpen"
      class="has-arrow"
      :class="{'active': isOpen}"
    >
      <i
        v-if="item.icon"
        :class="item.icon"
      />
      <span class="hide-menu">{{ item.title }}</span>
    </b-link>
    <b-collapse
      v-model="isOpen"
      tag="ul"
      accordion="sidebar"
    >
      <component
        v-for="(child, cKey) in item.children"
        :key="cKey"
        :is="resolveNavComp(child)"
        :item="child"
      />
    </b-collapse>
  </li>
</template>
<script>
import BoNavLink from '@/components/backend/navigation/BoNavLink.vue'
import BoNavGroup from '@/components/backend/navigation/BoNavGroup.vue'
import navUtils from '@/navigation/navUtils'

export default {
  name: 'BoNavGroup',
  components: {
    BoNavLink, BoNavGroup,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    active() {
      let mappedRoutes = this.item.children.map(child => child.route)
      return Boolean(mappedRoutes.find(el => el.name == this.$route.name))
    },
  },
  methods: {
    resolveNavComp(item) {
      return navUtils.resolveNavComp(item)
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  }
}
</script>