import _ from 'lodash'

const menu = [
  {
    title: 'Dashboard',
    route: {name: 'BoDashboard'},
    menu: 'BoDashboard',
    icon:  'icon-speedometer',
  },
  {
    cap: 'Website Management',
    relate: [
      'BoHome',
      'BoAboutUs',
      'BoAnakPerusahaan',
      'BoProducts',
      'BoProductCategory',
      'ArticleCat',
      'BoBlog',
      'BoBlogCategory',
      'BoContact',
      'BoOffice',
      'BoCareer',
      // 'BoApplicants',
      'BoClients',
      'BoLineProduction',
      'BoTermCondition',
      'BoPrivacyPolice',
      'BoWebSettings',
      'StaticSEO',
    ]
  },
  {
    title: 'Home',
    route: {name: 'BoHome'},
    icon:  'ti-home',
    menu: 'BoHome'
  },
  {
    title: 'About Us',
    route: {name: 'BoAboutUs'},
    icon:  'icon-people',
    menu: 'BoAboutUs'
  },
  {
    title: 'Anak Perusahaan',
    route: {name: 'BoAnakPerusahaan'},
    icon:  'ti-layers',
    menu: 'BoAnakPerusahaan'
  },
  {
    title: 'Clients',
    route: {name: 'BoClients'},
    icon:  'ti-id-badge',
    menu: 'BoClients'
  },
  {
    title: 'Line Production',
    route: {name: 'BoLineProduction'},
    icon:  'icon-settings',
    menu: 'BoLineProduction'
  },
  {
    title: 'Line Production List',
    route: {name: 'BoLineProductionList'},
    icon:  'icon-settings',
    menu: 'BoLineProductionList'
  },
  {
    title: 'Products',
    icon: 'icon-social-dropbox',
    children: [
      {
        title: 'Product List',
        route: {
          name: 'BoProducts'
        },
        menu: 'BoProducts'
      },
      {
        title: 'Product Category',
        route: {
          name: 'BoProductCategory'
        },
        menu: 'BoProductCategory'
      },
    ],
  },
  {
    title: 'Media',
    icon: 'ti-layout-list-thumb',
    children: [
      {
        title: 'News',
        route: {
          name: 'BoBlog'
        },
        menu: 'BoBlog'
      },
      {
        title: 'Event',
        route: {
          name: 'BoEvent'
        },
        menu: 'BoEvent'
      },
    ],
  },
  {
    title: 'CSR',
    icon: 'ti-camera',
    children: [
      {
        title: 'CSR List',
        route: {
          name: 'BoCSR'
        },
        menu: 'BoCSR'
      },
      // {
      //   title: 'CSR Category',
      //   route: {
      //     name: 'BoCSRCategory'
      //   },
      //   menu: 'BoCSRCategory'
      // },
    ],
  },
  {
    title: 'Contact Us',
    icon:  'icon-paper-plane',
    children: [
      {
        title: 'Contact List',
        route: {name: 'BoContact'},
        menu: 'BoContact'
      },
      {
        title: 'Contact Page',
        route: {name: 'BoContactPage'},
        menu: 'BoContactPage'      
      },
    ]
  },
  {
    title: 'Career',
    icon:  'icon-briefcase',
    children: [
      {
        title: 'Career Page',
        route: { name: 'BoCareer' },
        menu: 'BoCareer'
        
      },
      // {
      //   title: 'Applicants',
      //   route: { name: 'BoApplicants' },
      //   menu: 'BoApplicants'
      // },
    ]
  },
  {
    title: 'Legal',
    icon: 'icon-docs',
    children: [{
        title: 'Terms and Conditions',
        route: {
          name: 'BoTermCondition',
          params: {
            pageSlug: 'terms-and-conditions'
          }
        },
        menu: 'BoTermCondition',

      },
      {
        title: 'Privacy Policy',
        route: {
          name: 'BoPrivacyPolice',
          params: {
            pageSlug: 'privacy-policy'
          }
        },
        menu: 'BoPrivacyPolice',

      },
    ]
  },
  {
    title: 'Web Settings',
    icon: 'icon-wrench',
    route: { name: 'BoWebSettings' },
    menu: 'BoWebSettings'
  },
  {
    cap: 'CMS Settings',
    relate: [
      'Users',
      'BoUserLevel'
    ]
  },
  {
    title: 'User Management',
    icon: 'icon-user-follow',
    children: [
      {
        title: 'User List',
        route: {name: 'Users'},
        menu: 'Users'
      },
      {
        title: 'User Level',
        route: {name: 'BoUserLevel'},
        menu: 'BoUserLevel'
      },
    ],
  },
  // {
  //   title: 'AuditTrail',
  //   route: {
  //     name: 'AuditTrail'
  //   },
  //   icon: 'icon-check',
  //   menu: 'AuditTrail'
  // },
]

let nav = menu

const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      })
    }

    return v
  })
}

export default nav